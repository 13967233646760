<template>
    <dashboard-layout>
        <v-form v-if="latest_xray" class="latest">
            <v-row class="x-ray">
                <v-col cols="4">
                    <span> {{ $date(latest_xray.created_at).format("DD.MM.YYYY HH:mm") }} </span>
                    <img :src="latest_xray.file_url" class="screen" />
                </v-col>
                <v-col>
                    <h4>Комментарий врача</h4>
                    <v-card class="latest-comments" rounded="lg">
                        <v-card-text class="latest-note">
                            <span> {{ latest_xray.note }} </span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <v-form>
            <v-row class="x-ray">
                <v-col cols="4">
                    <span>Дата</span>
                    <CustomFileUpload
                        v-if="newXray.file_url === ''"
                        label="Загрузить фото"
                        v-model="newXray.file_url"
                        directory="xrays"
                        :showPreview = "showPreview"
                        class="body"
                    />
                    <img
                        v-if="newXray.file_url !== ''"
                        :src="newXray.file_url"
                        class="preview-img"
                    />
                </v-col>
                <v-col>
                    <h4>Комментарий врача</h4>
                    <v-card class="body" rounded="lg">
                        <v-card-text>
                            <v-textarea
                                dense
                                flat
                                solo
                                v-model="newXray.note"
                                placeholder="Оставьте комментарий из Вашей медицинской карты..."
                                elevation="0"
                                color="dark"
                            ></v-textarea>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <v-row justify="end">
            <v-col cols="auto">
                <CustomButton @click="saveXray">Сохранить</CustomButton>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../../../layouts/dashboard/Index";
import Xray from "../../../models/Xray";

export default {
    name: "dashboard-index",
    components: { DashboardLayout },
    computed: {},
    data: function () {
        return {
            xrays: [],
            newXray: {
                note: '',
                file_url: '',
            },
            url: '',
            latest_xray: {},
        };
    },
    methods: {
        async init() {
            this.xrays = await Xray.get();
            this.latest_xray = this.$auth.user().patient.latest_xray
            // $auth.user().patient.latest_xray.created_at
            // $auth.user().patient.latest_xray.file_url
            // $auth.user().patient.latest_xray.note
        },
        async saveXray() {
            if(this.newXray.note !== '' && this.newXray.file_url !== '') {
                console.log('newXray', this.newXray.file_url);
                let xray = new Xray(this.newXray);
                await xray.save();
                this.latest_xray = {
                    created_at: new Date(),
                    file_url: this.newXray.file_url,
                    note: this.newXray.note
                }
                this.newXray = {
                    note: '',
                    file_url: ''
                };
                // await this.init();
            } else {
                console.log('newXray', this.newXray);
                alert("PLease upload photo or add comments!");
            }
        },
        showPreview(url) {
            this.newXray.file_url = url;
        }
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped>
.x-ray {
    padding-left: 16px;
}
.body {
    margin-top: 20px;
}
.latest-comments {
    margin-top: 20px;
    height: 64%;
    object-fit: cover;
}
.latest-note {
    font-size: 17px;
}
.latest {
    height: 36%;
    margin-bottom: 40px;
}
.screen {
    width: 100%;
    height: 65%;
    border-radius: 10px;
    margin-top: 20px;
    object-fit: cover;
}
.preview-img {
    width: 100%;
    height: 204px;
    margin-top: 20px;
    border-radius: 10px;
}
</style>
